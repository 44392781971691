<template>
  <div>
    <change-language />
    <div class="d-flex flex-column flex-root">
      <!--begin::Login-->
      <div
        class="login login-2 d-flex flex-column flex-lg-row flex-row-fluid bg-white fill"
      >
        <!--begin::Aside-->
        <div
          class="login-aside position-relative overflow-hidden order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto py-9 px-7 py-lg-13 px-lg-35 w-100 max-w-600px"
        >
          <!--begin: Aside Container-->
          <div
            class="d-flex flex-row-fluid flex-column justify-content-between"
          >
            <!--begin::Logo-->
            <a href="#" class="text-center pt-2">
              <b-img-lazy
                src="@/assets/images/login/ky.png"
                class="max-h-75"
                alt=""
              />
            </a>
            <!--end::Logo-->
            <!--begin::Aside body-->
            <div class="d-flex flex-column-fluid flex-column flex-center">
              <!--begin::Forgot-->
              <transition
                enter-active-class="animate__animated animate__backInUp"
              >
                <div class="login-form login-forgot pt-5 w-100 max-w-450px">
                  <!--begin::Form-->
                  <b-form
                    class="form"
                    novalidate="novalidate"
                    @submit.stop.prevent="setPassword(passwordModel, user)"
                  >
                    <!--begin::Title-->
                    <div class="text-center pb-8">
                      <h3 class="font-weight-bolder text-dark display5">
                        {{ $t("login.token.title") }}
                      </h3>
                      <p class="text-muted font-weight-bold font-size-h4">
                        {{ $t("login.token.subtitle") }}
                      </p>
                    </div>
                    <!--end::Title-->
                    <!--begin::Form group-->
                    <div role="group" class="form-group">
                      <b-form-input
                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                        type="password"
                        :placeholder="$t('login.form.password')"
                        v-model="passwordModel.password"
                        :state="passwordErrorStatus"
                        autocomplete="new-password"
                        id="password"
                        :pattern="pwdRegex"
                      />
                      <b-form-input
                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6 mt-8"
                        type="password"
                        :placeholder="$t('login.form.repassword')"
                        v-model="passwordModel.repassword"
                        :state="passwordErrorStatus"
                        autocomplete="new-password"
                        id="repassword"
                        :pattern="pwdRegex"
                      />
                      <b-form-invalid-feedback
                        id="password-feedback"
                        v-if="status !== ''"
                      >
                        <span v-if="status !== 'passwordFormat' && !errCode">
                          {{ callGetError("repassword") }}
                        </span>
                        <span v-if="errCode == 'ND1038'">
                          {{ callGetError("ND1038") }}
                        </span>
                        <span v-else>
                          {{ callGetError("password_format") }}
                        </span>
                      </b-form-invalid-feedback>
                    </div>
                    <!--end::Form group-->
                    <b-form-group
                      class="fv-plugins-icon-container text-left"
                      v-if="statusConditions"
                    >
                      <b-form-checkbox
                        v-model="user.termsOfUse"
                        :state="user.termsOfUse"
                        class="mb-3 mr-1"
                        size="lg"
                        :required="true"
                        style="display: inline-block"
                      >
                      </b-form-checkbox>
                      <div style="display: inline-block;">
                        <a
                          @click="$bvModal.show('modal-terms')"
                          href="javascript:void(0)"
                        >
                          {{ $t("login.conditions.termsOfUse") }}
                        </a>
                      </div>
                      <div class="clearfix" />
                      <b-form-checkbox
                        v-model="user.privacyPolicy"
                        class="mb-3 mr-1"
                        id="conditions"
                        :state="user.privacyPolicy"
                        size="lg"
                        :required="true"
                        style="display: inline-block"
                      >
                      </b-form-checkbox>
                      <div style="display: inline-block;">
                        <a
                          @click="$bvModal.show('modal-privacy')"
                          href="javascript:void(0)"
                        >
                          {{ $t("login.conditions.privacyPolicy") }}
                        </a>
                      </div>
                      <b-form-invalid-feedback
                        id="conditions-feedback"
                        :state="status !== 'conditions'"
                      >
                        {{ callGetError(status) }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!--end::Form group-->
                    <!--begin::Form group-->
                    <div
                      class="form-group d-flex flex-wrap flex-center pb-lg-0 pb-3"
                    >
                      <b-button
                        type="submit"
                        pill
                        variant="primary"
                        class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                      >
                        {{ $t("login.buttons.save") }}
                      </b-button>
                      <b-button
                        type="button"
                        pill
                        variant="light-primary"
                        :to="{ name: 'Login' }"
                        class="font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-4"
                      >
                        {{ $t("login.buttons.cancel") }}
                      </b-button>
                    </div>
                    <!--end::Form group-->
                  </b-form>
                  <!--end::Form-->
                </div>
              </transition>
              <!--end::Forgot-->
            </div>
            <!--end::Aside body-->
          </div>
          <!--end: Aside Container-->
        </div>

        <!--begin::Aside-->
        <!--begin::Content-->
        <div
          class="content order-1 order-lg-2 flex-column-row-auto flex-grow-1 pt-md-0 pb-40 bgi-no-repeat bgi-position-y-bottom bgi-position-x-center login-right"
        >
          <!--begin::Content body-->
          <div class="d-flex flex-center pb-lg-40 pt-lg-0 pt-md-0 pb-40">
            <div
              class="d-flex flex-column justify-content-center text-center pt-xl-30 pt-lg-20 pb-sm-10"
            >
              <h1
                class="display4 font-weight-bolder my-7 text-dark"
                style="font-size: 4rem !important"
              >
                <span
                  v-for="(value, index) in $t('login.header')"
                  v-bind:key="index"
                  >{{ value }}<br
                /></span>
              </h1>
              <p
                class="font-weight-bolder font-size-h4 font-size-lg text-dark opacity-70 pb-lg-3 pb-sm-40 pb-10"
                style="font-size: 2rem !important;font-weight: lighter !important"
              >
                <span
                  v-for="(value, index) in $t('login.subheader')"
                  v-bind:key="index"
                  >{{ value }}<br
                /></span>
              </p>
            </div>
          </div>
          <!--end::Content body-->
        </div>
        <b-modal
          id="modal-privacy"
          hide-footer
          size="xl"
          scrollable
          :title="$t('legal.privacy.title')"
        >
          <div
            class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
            v-for="(content, index) in $t('legal.privacy.contents')"
            :key="content.title"
          >
            <h3>{{ index + 1 }}- {{ content.title }}</h3>
            <p v-html="content.content"></p>
            <span
              v-for="(child, subindex) in content.children"
              v-bind:key="child.title"
            >
              <h6>
                {{ index + 1 }}.{{ subindex + 1 }} -
                <span v-html="child.title" />
              </h6>
              <span v-html="child.content" />
            </span>
          </div>
        </b-modal>
        <b-modal
          id="modal-terms"
          hide-footer
          size="xl"
          scrollable
          :title="$t('legal.terms.title')"
        >
          <div
            class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
            v-for="(content, index) in $t('legal.terms.contents')"
            :key="content.title"
          >
            <h3>{{ index + 1 }}- {{ content.title }}</h3>
            <p v-html="content.content"></p>
            <span
              v-for="(child, subindex) in content.children"
              v-bind:key="child.title"
            >
              <h6>
                {{ index + 1 }}.{{ subindex + 1 }} -
                <span v-html="child.title" />
              </h6>
              <span v-html="child.content" />
            </span>
          </div>
        </b-modal>
        <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<script>
import ChangeLanguage from "@/components/ChangeLanguage.component.vue";

import { mapActions } from "vuex";
import store from "@/store";
import _ from "lodash";

import { getError } from "@/utils/helpers";

import {
  checkToken,
  acceptConditions,
  resetPassword,
  language
} from "@/api/auth.api";
import { getConstants } from "@/api/misc.api";
import { validPassword, pwdRegex } from "@/utils/validators";

export default {
  name: "Token",
  components: {
    ChangeLanguage
  },
  data() {
    return {
      user: {
        termsOfUse: null,
        privacyPolicy: null
      },
      passwordModel: {
        password: "",
        repassword: ""
      },
      status: "",
      token: "",
      statusConditions: false,
      tokenResponse: {},
      errCode: false,
      pwdRegex: pwdRegex,
      applicationCode: ""
    };
  },
  computed: {
    passwordErrorStatus() {
      if (
        this.status === "repassword" ||
        this.status === "empty" ||
        this.status === "passwordFormat"
      )
        return false;
      else if (
        this.passwordModel.password === this.passwordModel.repassword &&
        this.passwordModel.password !== "" &&
        validPassword(this.passwordModel.password)
      )
        return true;
      else return null;
    }
  },
  beforeCreate() {
    var params = {};
    if (localStorage.getItem("language")) {
      params.locale = localStorage.getItem("language");
    }
    params.token = this.$route.params.token;
    checkToken(params)
      .then(response => {
        if (response.status === 200) {
          store.dispatch(
            "Auth/changeLocalLanguage",
            response.data.records.locale.toLowerCase()
          );
          if (response.data.records.federated) {
            location.replace(
              `${process.env.VUE_APP_SAML_SERVER}/${process.env.VUE_APP_API_VERSION}${response.data.records.key}/sso`
            );
          } else {
            this.tokenResponse = response.data.records;
            this.user.privacyPolicy =
              response.data.records.privacyPolicy === 1 ? true : false;
            this.user.termsOfUse =
              response.data.records.termsOfUse === 1 ? true : false;
            if (!this.user.privacyPolicy || !this.user.termsOfUse) {
              this.statusConditions = true;
              this.user.privacyPolicy = null;
              this.user.termsOfUse = null;
            }
            this.services = response.data.records.services;
            this.token = response.data.records.token;
          }
        } else {
          this.$swal("", this.callGetError("password_expired"), "error");
          this.$router.push({ name: "Login" });
        }
      })
      .catch(() => {
        this.$swal("", this.callGetError("password_expired"), "error");
        this.$router.push({ name: "Login" });
      });
  },
  methods: {
    ...mapActions("Auth", ["setToken", "setServices", "changeLocalLanguage"]),
    callGetError(code, params) {
      return getError(code, params);
    },
    async setPassword(passwordModel, user) {
      if (passwordModel.password === "") {
        this.status = "empty";
        this.errCode = "empty";
      } else if (passwordModel.password !== passwordModel.repassword) {
        this.status = "repassword";
        this.errCode = "repassword";
      } else if (!validPassword(passwordModel.password)) {
        this.status = "passwordFormat";
        this.errCode = "passwordFormat";
      } else if (!user.termsOfUse || !user.privacyPolicy) {
        this.status = "conditions";
        this.errCode = "conditions";
      } else {
        if (this.statusConditions) {
          var conditions = {
            termsOfUse: this.user.termsOfUse,
            privacyPolicy: this.user.privacyPolicy,
            token: this.token
          };
          await acceptConditions(conditions, {
            headers: { Authorization: `Bearer ${this.token}` }
          });
        }
        localStorage.setItem("token", this.token);
        resetPassword({
          newPassword: Buffer.from(this.passwordModel.password).toString(
            "base64"
          )
        })
          .then(() => {
            this.$swal("", this.$t("login.token.success"), "success");
            localStorage.setItem(
              "stakeholderId",
              this.tokenResponse.stakeholderUserId
            );
            localStorage.setItem("profile", this.tokenResponse.profile);
            localStorage.setItem(
              "companyId",
              this.tokenResponse.stakeholderUserId
            );
            localStorage.setItem(
              "companyId",
              this.tokenResponse.stakeholderCompanyId
            );
            this.setToken();
            var languages = _.intersection(
              Object.keys(this.$i18n.messages),
              this.tokenResponse.languages
            );

            if (_.includes(languages, localStorage.getItem("language")))
              this.tokenResponse.locale = localStorage.getItem("language");

            language("locale=" + this.tokenResponse.locale.toLowerCase()).then(
              () => {
                this.changeLocalLanguage(this.tokenResponse.locale);
              }
            );
            getConstants("SERVICETYPE_").then(servicesResp => {
              this.setServices({
                services: servicesResp.data.records,
                userServices: this.services
              });
            });
            this.$router.push({ name: "Dashboard" });
          })
          .catch(error => {
            if (error.response.status === 412) {
              this.passwordModel.password = "";
              this.passwordModel.repassword = "";
              this.status = "passwordFormat";
              this.errCode = error.response.data.records.applicationCode;
            }
            localStorage.removeItem("token");
          });
      }
    }
  },
  watch: {
    status: function(value) {
      if (value === "passwordFormat" || value === "repassword") {
        this.passwordModel.password = "";
        this.passwordModel.repassword = "";
      } else if (value === "success") {
        //TODO: Set the rest
        this.$router.push({ name: "Dashboard" });
      } else if (value === "conditions") {
        this.user.termsOfUse = !this.user.termsOfUse ? false : true;
        this.user.privacyPolicy = !this.user.privacyPolicy ? false : true;
      }
    },
    passwordModel: {
      deep: true,
      handler(value) {
        if (
          this.status !== "" &&
          (value.password !== "" || value.repassword !== "")
        )
          this.status = "";
      }
    }
  }
};
</script>

<style lang="scss" src="@/assets/scss/user/style.scss"></style>
<style src="@/assets/scss/login.scss" lang="scss" scoped></style>
