<template>
  <ul
    class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4 dropdown-menu-left"
  >
    <!--begin::Item-->
    <li
      class="nav-item mb-1"
      data-placement="left"
      v-for="language in languages"
      :key="language"
    >
      <b-button
        tag="a"
        :class="
          `btn btn-sm btn-icon btn-bg-light btn-icon-success btn-hover-success ` +
            (lang === language && 'active')
        "
        @click="changeLocalLanguage(language.toLowerCase())"
      >
        <span class="symbol symbol-20">
          <img :src="require(`@/assets/images/flags/${language}.svg`)" alt="" />
        </span>
      </b-button>
    </li>
    <!--end::Item-->
  </ul>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChangeLanguage",
  computed: {
    ...mapGetters("Auth", ["lang"]),
    languages() {
      return Object.keys(this.$i18n.messages);
    },
    actualLanguage() {
      if (_.indexOf(this.languages, this.lang) > -1) {
        return this.lang;
      } else {
        return "es-es";
      }
    }
  },
  methods: {
    ...mapActions("Auth", ["changeLocalLanguage"])
  }
};
</script>
